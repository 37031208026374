<template>
	<header class="global-header" :class="[interactingWithNav == true ? 'global-nav--interacting' : '']" @mouseenter="interactingWithNav = true" @mouseleave="interactingWithNav = false" v-if="navData">
		<a href="#content" id="skip-navigation">Skip Navigation</a>

		<nuxt-link to="/" class="global-logo"><img width="77" height="45" v-lazy-load data-src="/img/ui/logo-bn.svg" alt="B+N Logo"/></nuxt-link>

		<button :aria-expanded="showNav" @click="showNav = !showNav" class="menu-button" type="button">{{ showNav == false ? 'Menu' : 'Close' }}</button>

		<nav class="global-nav" :class="[showNav == true ? 'global-nav--open' : '']" ref="globalNav" v-if="this.navData.story">
			<ul class="global-nav-list">
				<li v-for="(navLink, index) in getLinks()" :key="navLink._uid">
					<template v-if="navLink.has_subnav">
						<nuxt-link :event="toggleEvent" :to="'/' + navLink.link.cached_url + '/'" @click.native="toggleSubNav" class="global-nav-list__link has-subnav" @focus.native="interactingWithNav = true" :aria-hidden="ariaNav" ref="topLevelLink">{{ navLink.link.story.name }}</nuxt-link>
						<div class="global-subnav-dropdown">
							<div class="global-subnav-dropdown--nav">
								<nuxt-link :to="'/' + navLink.link.cached_url" class="global-subnav-dropdown--title">{{ navLink.dropdown_title ? navLink.dropdown_title : navLink.link.story.name }}</nuxt-link>
								<ul class="global-subnav-list" :aria-hidden="ariaSubNav">
									<li>
										<button class="global-nav__back" @click="goBack(index)" :tabindex="tabSubNav == true ? 0 : -1">Back</button>
										<nuxt-link :to="'/' + navLink.link.cached_url + '/'" class="global-nav-list__link" :tabindex="tabSubNav == true ? 0 : -1" @click.native="exitNav()">See All {{ navLink.link.story.name }}</nuxt-link>
									</li>
									<li v-for="subNavLink in navLink.subnav_link" :key="subNavLink._uid">
										<!-- Automatically adding external icon for Configurator route
										<template v-if="subNavLink.link.cached_url.endsWith('configurator')">
											<nuxt-link :to="'/' + subNavLink.link.cached_url + '/'" class="global-nav-list__link" @click.native="exitNav()" target="_blank" rel="noopener">{{ subNavLink.link.story.name }}</nuxt-link>
										</template> -->
										<!-- Other/Internal Sub-nav Links
										<template v-else> -->
										<nuxt-link :to="'/' + subNavLink.link.cached_url + '/'" class="global-nav-list__link" :tabindex="tabSubNav == true ? 0 : -1" @click.native="exitNav()">{{ subNavLink.display_name ? subNavLink.display_name : subNavLink.link.story.name }}</nuxt-link>
										<!--</template>-->
									</li>
								</ul>
							</div>
							<div class="global-subnav-dropdown--content">
								<template v-if="navLink.megamenu">
									<component 
									v-for="megamenu in navLink.megamenu" 
									:key="megamenu._uid" 
									:blok="megamenu" :is="megamenu.component | dashify"></component>
								</template>
							</div>
						</div>
						
					</template>

					<nuxt-link v-else :to="'/' + navLink.link.cached_url + '/'" class="global-nav-list__link">{{ navLink.link.story.name }}</nuxt-link>
				</li>

				<li>
					<nuxt-link v-if="loggedIn" to="/account/" class="global-nav-list__link icon-account icon-account--active" @click.native="exitNav()">Account</nuxt-link>
					<a v-else href="/account/" class="global-nav-list__link icon-account" @click.prevent="logIn">Account</a>
				</li>
			</ul>
		</nav>
	</header>
</template>

<style lang="scss">
#skip-navigation {
	--timing: 150ms;

	align-items: center;
	background-color: var(--orange);
	color: var(--white);
	display: flex;
	font-size: 1em;
	justify-content: center;
	left: 0;
	overflow: hidden;
	padding: 10px;
	position: absolute;
	height: var(--header-height);
	text-align: center;
	text-decoration: none;
	top: 0;
	transform: translateX(-100%);
	transition: transform var(--timing) var(--easing);
	z-index: 5;

	&:active,
	&:focus {
		outline: none;
		transform: translateX(0);
	}
}

/* Header */
.global-header {
	--box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
	background-color: var(--white);
	box-shadow: var(--box-shadow);
	height: var(--header-height);
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 10;
}

/* Logo */
.global-logo {
	display: inline-block;
}

.global-logo img {
	max-height: 30px;
	width: auto;
}

/* Nav List */
.global-nav-list {
	margin-block-end: 0;

	a {
		flex: 0 0 auto;
		text-decoration: none;
	}

	.icon-account {
		background: center center no-repeat;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
	}

	.icon-account:not(.icon-account--active) {
		/* Note there are multiple states/versions of the Account SVG icon. Had to do it this way because Nuxt wouldn't consistently update CSS custom variables defined on an inline SVG.*/
		/* Version: Black Stroke, White Fill */
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' fill-rule='evenodd' stroke='%23000'%3E%3Ccircle cx='9' cy='9' r='8.5' fill='%23fff'/%3E%3Ccircle cx='9' cy='7' r='2.5'/%3E%3Cpath d='M1.961 14.632c2.506-1.792 4.805-2.688 6.9-2.688s4.487.896 7.179 2.688'/%3E%3C/svg%3E");
		background-size: 24px;
		margin-top: -2px;
	}
	.icon-account--active {
		/* Version: White Stroke, Orange Fill */
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' fill-rule='evenodd' stroke='%23fff'%3E%3Ccircle cx='9' cy='9' r='8.5' fill='%23f50'/%3E%3Ccircle cx='9' cy='7' r='2.5'/%3E%3Cpath d='M1.961 14.632c2.506-1.792 4.805-2.688 6.9-2.688s4.487.896 7.179 2.688'/%3E%3C/svg%3E");
	}
}

/* Up to Custom Large Screen */
@media all and (max-width: 1179px) {
	/* Header */
	.global-header {
		padding: 15px 25px;
	}

	/* Nav */
	.global-nav {
		background-color: transparent;
		display: none;
		flex-direction: column;
		height: calc(100vh - var(--header-height));
		left: 0;
		/* min-height: -webkit-fill-available; */
		overflow-y: scroll;
		opacity: 0;
		padding: 50px 0 25px;
		position: absolute;
		top: 0;
		transform: translateY(-100%);
		transition: background-color 150ms linear;
		width: 100%;

		ul {
			padding-inline-start: 25px;
		}

		a {
			display: inline-block;
			font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
			font-size: 1.5rem;
			margin-block-end: 25px;
		}

		/* Open State */
		&--open {
			background-color: var(--white);
			display: flex;
			opacity: 1;
			transform: translateY(var(--header-height));
		}
	}

	.global-header--absolute {
		position: absolute;
	}


	/* Nav List */
	.global-nav-list {
		.icon-account {
			background-size: 28px;
			background-position: left center;
			display: flex;
			height: 44px;
			margin-block-start: 40px;
			width: 44px;
		}
	}

	/* Back Button */
	.global-nav__back {
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='19'%3E%3Cg fill='none' stroke='%23000' transform='matrix(-1 0 0 1 19 1)' stroke-width='1.5'%3E%3Cpath d='M0 7.903h17.502'/%3E%3Cpath d='M8.751 0l8.751 7.931L8.751 16'/%3E%3C/g%3E%3C/svg%3E") left center no-repeat;
		display: block;
		height: 50px;
		margin-block-end: 10px;
		overflow: hidden;
		text-indent: 100%;
		width: 50px;
	}

	/* Sub-Nav List */
	.global-subnav-list {
		background-color: var(--white);
		//display: none; For testing animation approach
		left: 0;
		min-height: calc(100vh - var(--header-height));
		opacity: 0;
		position: absolute;
		top: 0;
		transform: translateX(-100%);
		transition: opacity var(--timing) var(--easing), transform var(--timing) var(--easing);
		width: 100%;

		li:first-of-type {
			//height: 50px;
		}

		a {
			font-family: 'Teodor-Regular', 'Times New Roman', Times, serif;
			font-size: 1.5rem;
		}
	}

	/* Open State */
	//a.global-subnav--open {
	//display: none; For testing animation approach
	//}
	.global-subnav--open + .global-subnav-dropdown .global-subnav-list {
		//animation: slideIn var(--timing) var(--easing) forwards; For testing animation approach
		//display: block; For testing animation approach
		opacity: 1;
		transform: translateX(0);
	}

	/* Menu Button */
	.menu-button {
		align-items: center;
		display: flex;
		flex-shrink: 0;
		font-size: 1.125rem;
		height: 60px;
		padding-block-start: 8px;
		position: absolute;
		right: 25px;
		top: 0;
		z-index: 1;
	}
}
/* Using animation to transition from hidden to visible instead of trying to toggle aria attributes. Caveat is that the slide out animation is lost. */
@keyframes slideIn {
	0% {
		display: none;
		opacity: 0;
	}
	0.1% {
		display: block;
		opacity: 0;
		transform: translateX(-100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.global-subnav-dropdown--content, .global-nav .global-subnav-dropdown--title {
	display: none;
}

/* Custom Large Screen & Up */
@media all and (min-width: 1180px) {
	.global-nav .global-subnav-dropdown--title, .global-subnav-dropdown--content {display: block;}
	.global-nav .global-subnav-dropdown--title {
		text-decoration: none !important;
	}
	/* Header */
	.global-header {
		display: flex;
		padding: 0 20px;
		padding: 0 max(20px, 50vw - 1400px / 2);
		// overflow: hidden;
		position: fixed;
		transition: height var(--timing) var(--easing);

		+ main {
			padding-block-start: 80px;
		}
	}

	.global-header--transparent {
		background-color: unset;
		box-shadow: unset;
		 transition: background-color 0.1s var(--easing), box-shadow var(--timing) var(--easing), height var(--timing) var(--easing);

		a {
			color: var(--white);
			transition: color var(--timing) var(--easing);
		}

		.global-nav-list {
			.icon-account {
				transition: background var(--timing) var(--easing);
			}
			.icon-account:not(.icon-account--active) {
				/* Version: White Stroke, No Fill */
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' fill-rule='evenodd' stroke='%23fff'%3E%3Ccircle cx='9' cy='9' r='8.5' /%3E%3Ccircle cx='9' cy='7' r='2.5'/%3E%3Cpath d='M1.961 14.632c2.506-1.792 4.805-2.688 6.9-2.688s4.487.896 7.179 2.688'/%3E%3C/svg%3E");
			}
		}
	}

	.global-header--absolute {
		position: absolute;
	}

	/* Logo */
	.global-logo {
		align-self: flex-start;
		flex-shrink: 0;
		margin-block-start: 24px;

		img {
			height: 30px;
			max-height: 30px;
		}
	}

	/* Nav */
	.global-nav {
		margin-inline-start: auto;
		padding-inline-start: 0px;
		/* Note that this 1180px value (which is used multiple times in this file) aligns with a media query in _variables.scss. So if this needs to change, all values also need to be updated. */
		width: min(1180px, 100%);

		.active,
		.active-exact {
			text-decoration: underline;
			text-decoration-thickness: 2px;
		}
	}

	/* Nav List */
	.global-nav-list {
		display: inline-flex;
		min-height: 100%;
		width: min(1180px, 100%);

		> li {
			align-items: flex-start;
			display: inline-flex;
			flex-direction: column;
			padding-left: 42px;
			padding-right: 42px;

			> a {
				height: var(--header-height);
				text-underline-offset: 7px;
				padding-bottom: 3px;
			}

			&:hover > a {
				text-decoration: underline;
				text-decoration-thickness: 2px;
			}
		}

		> li:last-child {
			margin-left: auto;
			width: auto;

			ul {
				/* TEMP */
				display: none !important;
			}
		}
		> li:nth-child(7) {
			margin-inline-start: 40px;
			width: auto;

			ul {
				/* TEMP */
				display: none !important;
			}
		}

		&__link {
			align-items: center;
			display: inline-flex;
			height: 100%;
			overflow: hidden;
			position: relative;
		}
	}

	/* Sub-Nav List */
	.global-subnav-list {
		line-height: 24px;
		li:first-of-type {
			display: none;
		}

		a {
			padding: 6px 15px 6px 0;
		}
	}

	/* Menu Button */
	.menu-button {
		display: none;
	}

	.global-subnav-dropdown {
		opacity: 0;
		box-shadow: none; 
		max-height: 0;
		overflow: hidden;
		position: absolute;
		top: 100%;
		width: 100%;
		left: 0;
		padding: 0 max(20px, 50vw - 1400px / 2);
		background: #fff;
		background-color: var(--white);
		transition: background-color var(--timing) var(--easing), box-shadow var(--timing) var(--easing), max-height var(--timing) var(--easing);
		display: flex;
		align-items: flex-start;
		justify-content: stretch;

		a {
			color: var(--black);
		}

		&--nav {
			padding: 20px 0px;
			width: 230px;
		}

		&--content {
			padding: 20px 0px;
			width: calc(100% - 230px);

			&:has( > .description-only) {
				order: -1;
				width: calc(100% - 354px);
			}
		}

		&--title {
			font-size: 24px;
			display: block;
			line-height: 28px;
			margin-bottom: 24px;
			font-family: "Teodor-Regular", "Times New Roman", Times, serif;
		}
	}

	/* Hover */
	@media (hover: hover) and(pointer: fine) {
		.global-nav--interacting:focus-within,
		.global-nav--interacting:hover {
			// height: auto;
			// transition-delay: 225ms;
		}
		.global-nav--interacting .global-nav-list > li:focus-within,
		.global-nav--interacting .global-nav-list > li:hover {
			.global-subnav-dropdown {
				opacity: 1;
				box-shadow: 0px 4px 4px rgba(0,0,0,0.2);
				max-height: 2000px;
				transition-delay: 225ms;
			}
		}
		.global-header--transparent.global-nav--interacting:focus-within,
		.global-header--transparent.global-nav--interacting:hover {
			background-color: var(--white);
			box-shadow: var(--box-shadow);

			a {
				color: var(--black);
			}

			.icon-account:not(.icon-account--active) {
				/* Version: Black Stroke, White Fill */
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' fill-rule='evenodd' stroke='%23000'%3E%3Ccircle cx='9' cy='9' r='8.5' /%3E%3Ccircle cx='9' cy='7' r='2.5'/%3E%3Cpath d='M1.961 14.632c2.506-1.792 4.805-2.688 6.9-2.688s4.487.896 7.179 2.688'/%3E%3C/svg%3E");
			}
		}

		/* Sub-Nav List */
		.global-subnav-list a:hover {
			text-decoration: underline;
		}
	}

	.megamenu-columns {
		display: flex;
		width: 100%;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		&.three {
			.megamenu-card {
				width: 33.33%;
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		&.four {
			.megamenu-card {
				width: 25%;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

}
</style>

<script>
export default {
    props: ["blok"],
    data() {
        return {
            interactingWithNav: "",
            showNav: false,
            ariaNav: false,
            ariaSubNav: true,
            tabSubNav: false,
            toggleEvent: ""
        };
    },
    computed: {
        navData() {
            return this.$store.getters["globalHeader/getNavData"];
        },
        loggedIn() {
            return this.$auth.loggedIn;
        }
    },
    methods: {
        exitNav: function () {
            this.interactingWithNav = false;
            this.buttonText = "Menu";
            this.showNav = false;
            this.ariaNav = false;
            this.ariaSubNav = true;
        },
        toggleSubNav: function (event) {
            this.ariaNav = !this.ariaNav;
            this.ariaSubNav = !this.ariaSubNav;
            this.tabSubNav = !this.tabSubNav;
            if (process.client) {
                if (window.matchMedia("(max-width: 1179px)").matches) {
                    event.target.classList.toggle("global-subnav--open");
                }
                if (window.matchMedia("(min-width: 1180px)").matches) {
                    this.interactingWithNav = false;
                    this.showNav = false;
                    event.target.classList.remove("global-subnav--open");
                }
            }
        },
        goBack: function (index) {
            this.ariaNav = false;
            this.ariaSubNav = true;
            this.tabSubNav = false;
            this.$refs.topLevelLink[index].$el.classList.remove("global-subnav--open");
        },
        logIn: function () {
            this.showNav = false;
            this.$bus.$emit("openModal");
        },
        getLinks() {
            if (this.navData.story && this.navData.story.content) {
                return this.navData.story.content.globalnav_links;
            }
            return {};
        },
    },
    mounted() {
        // this.$store.dispatch("globalHeader/fetchAllNavData");
        // Reset Header when browser is resized between small and large breakpoint
        if (process.client) {
            const mq = window.matchMedia("(min-width: 1180px)");
            const resetHeader = () => {
                if (mq.matches) {
                    this.buttonText = "Menu";
                    this.showNav = false;
                    this.ariaNav = false;
                    this.ariaSubNav = true;
                    this.tabSubNav = true;
                    this.toggleEvent = "click";
                    // Reset Sub-Nav if Open
                    if (this.$refs.topLevelLink) {
                        Array.prototype.forEach.call(this.$refs.topLevelLink, function (el) {
                            el.$el.classList.remove("global-subnav--open");
                        });
                    }
                }
                else {
                    this.tabSubNav = false;
                    this.toggleEvent = "";
                }
            };
            mq.addListener(resetHeader);
            resetHeader();
        }
        // Handle Escape Key
        document.addEventListener("keydown", e => {
            if (e.key == "Escape" && this.showNav == true) {
                // Close Nav
                this.exitNav();
            }
        });
    }
}
</script>
