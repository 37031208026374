var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.navData
    ? _c(
        "header",
        {
          staticClass: "global-header",
          class: [
            _vm.interactingWithNav == true ? "global-nav--interacting" : ""
          ],
          on: {
            mouseenter: function($event) {
              _vm.interactingWithNav = true
            },
            mouseleave: function($event) {
              _vm.interactingWithNav = false
            }
          }
        },
        [
          _c("a", { attrs: { href: "#content", id: "skip-navigation" } }, [
            _vm._v("Skip Navigation")
          ]),
          _vm._v(" "),
          _c("nuxt-link", { staticClass: "global-logo", attrs: { to: "/" } }, [
            _c("img", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              attrs: {
                width: "77",
                height: "45",
                "data-src": "/img/ui/logo-bn.svg",
                alt: "B+N Logo"
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "menu-button",
              attrs: { "aria-expanded": _vm.showNav, type: "button" },
              on: {
                click: function($event) {
                  _vm.showNav = !_vm.showNav
                }
              }
            },
            [_vm._v(_vm._s(_vm.showNav == false ? "Menu" : "Close"))]
          ),
          _vm._v(" "),
          this.navData.story
            ? _c(
                "nav",
                {
                  ref: "globalNav",
                  staticClass: "global-nav",
                  class: [_vm.showNav == true ? "global-nav--open" : ""]
                },
                [
                  _c(
                    "ul",
                    { staticClass: "global-nav-list" },
                    [
                      _vm._l(_vm.getLinks(), function(navLink, index) {
                        return _c(
                          "li",
                          { key: navLink._uid },
                          [
                            navLink.has_subnav
                              ? [
                                  _c(
                                    "nuxt-link",
                                    {
                                      ref: "topLevelLink",
                                      refInFor: true,
                                      staticClass:
                                        "global-nav-list__link has-subnav",
                                      attrs: {
                                        event: _vm.toggleEvent,
                                        to: "/" + navLink.link.cached_url + "/",
                                        "aria-hidden": _vm.ariaNav
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.toggleSubNav($event)
                                        },
                                        focus: function($event) {
                                          _vm.interactingWithNav = true
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(navLink.link.story.name))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "global-subnav-dropdown" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "global-subnav-dropdown--nav"
                                        },
                                        [
                                          _c(
                                            "nuxt-link",
                                            {
                                              staticClass:
                                                "global-subnav-dropdown--title",
                                              attrs: {
                                                to:
                                                  "/" + navLink.link.cached_url
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  navLink.dropdown_title
                                                    ? navLink.dropdown_title
                                                    : navLink.link.story.name
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "ul",
                                            {
                                              staticClass: "global-subnav-list",
                                              attrs: {
                                                "aria-hidden": _vm.ariaSubNav
                                              }
                                            },
                                            [
                                              _c(
                                                "li",
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "global-nav__back",
                                                      attrs: {
                                                        tabindex:
                                                          _vm.tabSubNav == true
                                                            ? 0
                                                            : -1
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.goBack(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Back")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "nuxt-link",
                                                    {
                                                      staticClass:
                                                        "global-nav-list__link",
                                                      attrs: {
                                                        to:
                                                          "/" +
                                                          navLink.link
                                                            .cached_url +
                                                          "/",
                                                        tabindex:
                                                          _vm.tabSubNav == true
                                                            ? 0
                                                            : -1
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.exitNav()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "See All " +
                                                          _vm._s(
                                                            navLink.link.story
                                                              .name
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                navLink.subnav_link,
                                                function(subNavLink) {
                                                  return _c(
                                                    "li",
                                                    { key: subNavLink._uid },
                                                    [
                                                      _c(
                                                        "nuxt-link",
                                                        {
                                                          staticClass:
                                                            "global-nav-list__link",
                                                          attrs: {
                                                            to:
                                                              "/" +
                                                              subNavLink.link
                                                                .cached_url +
                                                              "/",
                                                            tabindex:
                                                              _vm.tabSubNav ==
                                                              true
                                                                ? 0
                                                                : -1
                                                          },
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.exitNav()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              subNavLink.display_name
                                                                ? subNavLink.display_name
                                                                : subNavLink
                                                                    .link.story
                                                                    .name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "global-subnav-dropdown--content"
                                        },
                                        [
                                          navLink.megamenu
                                            ? _vm._l(navLink.megamenu, function(
                                                megamenu
                                              ) {
                                                return _c(
                                                  _vm._f("dashify")(
                                                    megamenu.component
                                                  ),
                                                  {
                                                    key: megamenu._uid,
                                                    tag: "component",
                                                    attrs: { blok: megamenu }
                                                  }
                                                )
                                              })
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ]
                              : _c(
                                  "nuxt-link",
                                  {
                                    staticClass: "global-nav-list__link",
                                    attrs: {
                                      to: "/" + navLink.link.cached_url + "/"
                                    }
                                  },
                                  [_vm._v(_vm._s(navLink.link.story.name))]
                                )
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _vm.loggedIn
                            ? _c(
                                "nuxt-link",
                                {
                                  staticClass:
                                    "global-nav-list__link icon-account icon-account--active",
                                  attrs: { to: "/account/" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.exitNav()
                                    }
                                  }
                                },
                                [_vm._v("Account")]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass:
                                    "global-nav-list__link icon-account",
                                  attrs: { href: "/account/" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.logIn($event)
                                    }
                                  }
                                },
                                [_vm._v("Account")]
                              )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }